import React from 'react'

const Contact = () => {
  return <div className="contact">
    <main>
    <h1>
        Contact Us
    </h1>
    <form action="">
        <div>
            <label >Name</label>
            <input type="text" required placeholder='xyz' />
        </div>
        <div>
            <label >Email</label>
            <input type="Email" required placeholder='xyz@mail.com' />
        </div>
        <div>
            <label >message</label>
            <input type="text" required placeholder='Tell us about your query....' />
        </div>
        <button type='submit'>Sand</button>
    </form>
    </main>
    

  </div>
  
}

export default Contact