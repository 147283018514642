import React from "react";

const Footer = () => {
  return (
    <footer>
      <div>
        <h1>TechyStar</h1>
        <p>@all right reserved</p>
      </div>

      <div>
        <h5>Follow Us</h5>
        <div>
        <a href="https://nikhil12345.000webhostapp.com/" target={"blank"}>About ME</a>
        <a href="https://www.linkedin.com/in/nikhil-solanki-19bb5821a/" target={"blank"}>Linkdin</a>
        <a href="https://github.com/Nikhisol12345/" target={"blank"}>Github</a>
        <a href="https://www.instagram.com/nikhil_sol12345/" target={"blank"}>Instagram</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


