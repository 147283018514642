import React from "react";
import vg from "../assets/2.webp";
import {AiFillGoogleCircle,AiFillAmazonCircle,AiFillYoutube,AiFillInstagram} from "react-icons/ai"


const Home = () => {
  return (
    <>
      <div className="home" id="home">
        <main>
          <h1>teckystar</h1>
          <p>solution to all your problem</p>
        </main>
      </div>
      <div className="home2">
        <img src = {vg} alt="Graphics" />
        <div>
          <p>We are your one ans only solution ti the tech problems yu face every day. awe are leading tech company whose aim is to increase the problem solving abbility in children.</p>
        </div>
      </div>
      <div className="home3" id="About">
        <div>
          <h1>Who we are</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est enim molestias accusantium, fugit officiis repellat ducimus ex magnam! Hic, dignissimos doloribus. Ipsum, quia voluptatibus porro nihil quaerat libero fugit quas nam, a, amet ab ad quae expedita! Ipsa cumque officia, quia iste autem optio numquam exercitationem architecto magnam porro similique perferendis quos in, asperiores dolores doloribus a necessitatibus! Eum a fugiat aliquam explicabo eveniet totam cupiditate numquam, tenetur reiciendis vero quas accusamus id, obcaecati minima! Fugit nulla eligendi earum in aperiam consectetur minima ex, animi quis velit similique accusamus officia magnam placeat officiis maiores dignissimos aliquid et quasi sequi natus!</p>
        </div>
      </div>
      <div className="home4" id="brands">
        <div>
          <h1>Brands</h1>
          <article>
            <div style={{
              animationDelay:"0.3s"
            }}>
              <AiFillGoogleCircle/>
              <p>Google</p>
            </div>
            <div style={{
              animationDelay:"0.5s"
            }}>
              <AiFillAmazonCircle/>
              <p>Amazon</p>
            </div>
            <div style={{
              animationDelay:"0.7s"
            }}>
              <AiFillYoutube/>
              <p>Youtube</p>
            </div>
            <div style={{
              animationDelay:"1s"
            }}>
              <AiFillInstagram/>
              <p>Instagram</p>
            </div>
            
          </article>
        </div>

      </div>
    </>
  );
};

export default Home;
